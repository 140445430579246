body {
    position: relative;
    overflow: hidden;
    background: linear-gradient(to right, #000000, #434343);
  }
  
  .glitter {
    position: absolute;
    width: 1px;
    height: 1px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow:
      0 0 10px rgba(255, 255, 255, 0.9),
      10px 0 10px rgba(255, 255, 255, 0.9),
      -10px 0 10px rgba(255, 255, 255, 0.9),
      0 10px 10px rgba(255, 255, 255, 0.9),
      0 -10px 10px rgba(255, 255, 255, 0.9);
    animation: glitter-flicker infinite 1s linear;
  }
  
  @keyframes glitter-flicker {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.5;
    }
  }
  
  .homepage {
    text-align: center;
    padding: 20px;
    color: white;
  }
  
  .website-name {
    font-size: 46px;
    background-image: linear-gradient(to right, red, green, pink, yellow, blue);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  
  
  .website-description {
    font-size: 18px;
  }
  
  .card-container {
    max-height: 720px;
    overflow: scroll;
    max-width: 420px;
  }
  
  .card {
    width: 100%;
    margin: 10px;
    padding: 2px;
    border-radius: 10px;
    background-color: transparent;
    cursor: pointer;
  }

  .card-body{
    padding: 0;
  }
  
  .card img {
    width: 100%;
    border-radius: 50%;
    max-width: 60px;
    max-height: 60px;
  }
  
  .card h6 {
    margin-top: 10px;
    /* font-weight: bold; */
    color: white;
    font-size: .7rem;
    /* text-wrap: nowrap; */
  }
  