/* DynamicComponent.css */
.create-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    text-decoration: none;
}

.floating-share-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.floating-share-button .home-button {
    padding: 18px;
    background-color: #ae1b78;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 2rem;
    cursor: pointer;
}

.floating-share-button .home-button:hover {
    background-color: #0056b3;
}

.floating-share-button .share-button {
    padding: 18px;
    background-color: #ae1b78;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 2rem;
    cursor: pointer;
    line-height: normal;
}

.floating-share-button .share-button:hover {
    background-color: #0056b3;
}

a{
    color: white;
}

.dynamic-component-container{
    width: 420px;
    height: 920px;
    margin: auto;
}